define("discourse/plugins/discourse-whos-online/discourse/templates/connectors/discovery-list-container-top/online_users_widget", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{whos-online}}
  */
  {
    "id": "HMiH6Pi5",
    "block": "[[[41,[28,[32,0],[\"whos-online\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"whos-online\"],[[28,[32,1],[\"whos-online\"],null]]]]],[[[1,[52,[30,1,[\"whos-online\"]],[28,[30,1,[\"whos-online\"]],null,null],[28,[32,2],[[30,0],\"whos-online\",\"[\\\"The `whos-online` property path was used in the `discourse/plugins/discourse-whos-online/discourse/templates/connectors/discovery-list-container-top/online_users_widget.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.whos-online}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]]],[\"maybeHelpers\"],false,[\"if\",\"whos-online\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-whos-online/discourse/templates/connectors/discovery-list-container-top/online_users_widget.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});