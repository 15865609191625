define("discourse/plugins/discourse-whos-online/discourse/components/whos-online", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WhosOnline extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "whosOnline", [_service.inject]))();
    #whosOnline = (() => (dt7948.i(this, "whosOnline"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "count", [(0, _computed.readOnly)("whosOnline.count")]))();
    #count = (() => (dt7948.i(this, "count"), void 0))();
    get users() {
      return this.whosOnline.users?.slice(0, this.siteSettings.whos_online_maximum_display);
    }
    static #_3 = (() => dt7948.n(this.prototype, "users", [(0, _object.computed)("whosOnline.users.[]")]))();
    get hasUsers() {
      return this.users?.length >= this.siteSettings.whos_online_minimum_display;
    }
    static #_4 = (() => dt7948.n(this.prototype, "hasUsers", [(0, _object.computed)("users", "users.length")]))();
    get hasCount() {
      return this.count >= this.siteSettings.whos_online_minimum_display;
    }
    static #_5 = (() => dt7948.n(this.prototype, "hasCount", [(0, _object.computed)("count")]))();
    get isLong() {
      return this.count >= this.siteSettings.whos_online_collapse_threshold;
    }
    static #_6 = (() => dt7948.n(this.prototype, "isLong", [(0, _object.computed)("count")]))();
    get shouldDisplay() {
      if (this.count < this.siteSettings.whos_online_minimum_display && this.siteSettings.whos_online_hide_below_minimum_display) {
        return false;
      }
      return this.whosOnline.enabled;
    }
    static #_7 = (() => dt7948.n(this.prototype, "shouldDisplay", [(0, _object.computed)("count")]))();
  }
  _exports.default = WhosOnline;
});